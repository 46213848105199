/* global _paq */
import React, { useState, useEffect, useContext, useMemo } from 'react';
import ReactJWPlayer from 'react-jw-player';
import { withPrefix } from 'gatsby';
import DownloadReport from './DownloadReport';
import Slides from '../../streaming/slides';
import {
  PLAYER_EVENT_PLAY,
  PLAYER_EVENT_PAUSE,
  PRESENTATION_STATUS_ONDEMAND,
} from '../../../utils/constants';
import { playerEvent } from '../../../utils/analytics';
import PlayListThumbanilSlider from '../../streaming/playListThumbanilSlider';
import { brightCoveUrl } from "../../../utils/brightcove"
import { isMatomoEnabled } from '../../../data/global';
import { SocketContext } from '../../../context/socket';
import { handlePlayOndemandViewer } from '../../../utils/socket/socketOperations';
import { onDemandPlayerContext } from "../../../context/onDemandPlayer";

function toggleFullScreen() {
  const element = document.querySelector('.slide-show');
  if (!document.fullscreenElement) {
    element.requestFullscreen();
  } else if (document.exitFullscreen) {
    document.exitFullscreen();
  }
}

const OndemandStream = (props) => {
  const { accordian } = props;
  const { socket, isConnected } = useContext(SocketContext);
  let activeThubId = null;
  if (accordian.playList && accordian.playList.length) {
    accordian.playList = accordian.playList.filter(
      (value) => value.eventType === 'slideChange',
    );
    activeThubId = accordian.playList[0]._id;
  } else {
    accordian.playList = [];
  }

  let activeSlide = null;
  if (accordian.slides && accordian.slides.length) {
    activeSlide = accordian.slides[0]._id;
  }
  const [activeSlideID, setActiveSlideID] = useState(activeSlide);
  const [activeThumbanilSlideID, setActiveThumbanilSlideID] = useState(activeThubId);
  const [brithCoveVideoUrl, setBrithCoveVideoUrl] = useState(null);

  const [onDemandPlayerState, setOnDemandPlayerState] = useContext(onDemandPlayerContext)
  const brightCoveVideoRefId = accordian.presentation_brightcove_reference_id;
  const [activeChapterId, setActiveChapterId] = useState(null)
  useEffect(() => {
    let fetchBVideoUrl = async () => {
      const videoSrc = await brightCoveUrl({ brightCoveVideoRefId });
      if (videoSrc) {
        setBrithCoveVideoUrl(videoSrc)
      }
    }
    fetchBVideoUrl();
    function handleOnlineStatusChange() {
      if (navigator.onLine) {
        if (onDemandPlayerState && onDemandPlayerState.eventType === PLAYER_EVENT_PAUSE) {
          onPause();
        }
      }
    }
    window.addEventListener('online', handleOnlineStatusChange);
    return () => {
      window.removeEventListener('online', handleOnlineStatusChange);
    };

  }, [onDemandPlayerState])

  const generateVTTChapters = useMemo(() => {
    const chapterList = accordian?.chapterList;
    if (chapterList?.length) {
      let vttContent = chapterList.reduce((acc, chapter, index) => {
        const [startTime, endTime] = [chapter.startTime, chapter.startTime + chapter.duration].map(t => Math.floor(t / 1000).toString());
        return `${acc}\n\n${index + 1}\n${startTime} --> ${endTime}\n${chapter.name}`;
      }, "WEBVTT");
      return { file: `data:text/vtt;charset=utf-8,${encodeURIComponent(vttContent)}`, kind: 'chapters', label: 'English' };
    }
  }, [accordian?.chapterList])

  function onTime(time) {
    time = Math.round(time.position);
    const playlist = accordian.playList.filter(
      (value) => value.startTime <= time * 1000,
    );
    if (playlist && playlist.length) {
      const activeSlide = playlist.slice(-1)[0];
      setActiveSlideID(activeSlide.value);
      setActiveThumbanilSlideID(activeSlide._id);
      
      if (accordian?.chapterList?.length) {
        const matchedChapter = accordian.chapterList.find(({ startTime, duration }) => 
          time >= startTime / 1000 && time < (startTime + duration) / 1000
        );
        if (matchedChapter?._id !== activeChapterId) {
          setActiveChapterId(matchedChapter._id);
        }
      }
      
    }
  }

  function onPlay() {
    if (onDemandPlayerState && onDemandPlayerState.eventType === PLAYER_EVENT_PLAY) {
      onPause();
      setTimeout(function() {
        onCommonPlay();
      }, 5000);
    } else {
      onCommonPlay();
    }
  }
  function onCommonPlay() {
    playerEvent({
      eventType: PLAYER_EVENT_PLAY,
      presentationId: accordian._id,
      status: PRESENTATION_STATUS_ONDEMAND,
    });
    setOnDemandPlayerState({
      eventType: PLAYER_EVENT_PLAY,
      presentationId: accordian._id,
      status: PRESENTATION_STATUS_ONDEMAND,
    });
    if(isConnected){
      handlePlayOndemandViewer(socket,accordian._id);
     }
  }
  
  function onPause() {
    playerEvent({
      eventType: PLAYER_EVENT_PAUSE,
      presentationId: accordian._id,
      status: PRESENTATION_STATUS_ONDEMAND,
    })
    setOnDemandPlayerState({ 
      eventType: PLAYER_EVENT_PAUSE,
      presentationId: accordian._id,
      status: PRESENTATION_STATUS_ONDEMAND,
     })
  }

  function onResume() {
    playerEvent({
      eventType: PLAYER_EVENT_PLAY,
      presentationId: accordian._id,
      status: PRESENTATION_STATUS_ONDEMAND,
    })
    setOnDemandPlayerState({ 
      eventType: PLAYER_EVENT_PLAY,
      presentationId: accordian._id,
      status: PRESENTATION_STATUS_ONDEMAND,
     })
  }
  function onReady() {
    if(isMatomoEnabled){
      _paq.push(['MediaAnalytics::enableMediaAnalytics']);
      _paq.push(['MediaAnalytics::scanForMedia'])
    }
  }
  function onBuffer() {
    setTimeout(function() {
      onPause();
    }, 30000);
  }
  
  
  return (
    <div className="presentation-wrapper background-color-monochrome-1 pl-20 pr-20 pb-20 pt-20 pt-xs-10 pl-xs-10 pr-xs-10 pb-xs-10">
      {accordian.ondemadStreamUrl ? (
        <div className="row">
          <div className="col-sm-6">
            <div className={`${accordian.hideSlides ? "" : "presentation-image"} mb-xs-30`}>
              <ReactJWPlayer
                playerId={`ondemand-${accordian._id}`}
                playerScript={withPrefix('js/jwplayer-8.27.1/jwplayer.js')}
                licenseKey={process.env.JWPLAYER_LICENCE}
                onTime={onTime}
                onPlay={onPlay}
                onReady={onReady}
                onPause={onPause}
                onResume={onResume}
                onError={onPause}  
                // onBuffer={onBuffer}
                onOneHundredPercent={onPause}
                customProps={{ title: accordian.presentation_title, pipIcon: "disabled" }}
                playlist={{
                  file: accordian.ondemadStreamUrl,
                  tracks: [{
                      ...generateVTTChapters
                    }
                  ],
                }}
              />
            </div>
          </div>
          {accordian && !accordian.hideSlides && accordian.slides.length > 0 && (
            <div className="col-sm-6">
              <div className="presentation-image">
                <Slides
                  slides={accordian.slides}
                  onClick={() => toggleFullScreen()}
                  activeSlideID={activeSlideID}
                />
              </div>
            </div>
          )}
        </div>
      ) : (
        ''
      )}
      <div className="row">
        <div className="col-xs-12 mt-20 ">
          {accordian && !accordian.hideSlides && accordian.playList && (
            <PlayListThumbanilSlider
              playList={accordian.playList}
              activeSlideID={activeSlideID}
              activeThumbanilSlideID={activeThumbanilSlideID}
            />
          )}
        </div>
      </div>
      {!!accordian.presentation_desc && (
        <div className="row">
          <div className="col-xs-12 mt-20 ">
            <div
              className="text-size-medium font-secondary-regular color-primary-1"
              dangerouslySetInnerHTML={{
                __html: accordian.presentation_desc,
              }}
            />
          </div>
        </div>
      )}

      <div className="row">
        <div className="col-sm-6">
          {brithCoveVideoUrl ? (
            <ReactJWPlayer
              playerId={`res-${accordian._id}`}
              playerScript={withPrefix("js/jwplayer-8.27.1/jwplayer.js")}
              file={brithCoveVideoUrl}
              licenseKey={process.env.JWPLAYER_LICENCE}
              onReady={onReady}
              customProps={{ title: accordian.presentation_title + " Brightcove Video", pipIcon: "disabled" }}
            />
          ) : (
            " "
          )}
        </div>
      </div>

      {accordian.documents.length > 0 ? (
        <div className="download-section d-sm-flex width-full xs-wrap">
          {accordian.documents.map((data, index) => <DownloadReport data={data} key={index} />)}
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

export default OndemandStream;
